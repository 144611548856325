import Box from '@mui/material/Box';
import clsx from 'clsx';

import fontsStyles from '../../styles/fontStyles';
import useStyles from './styles';

const ItemWithIcon = ({ icon, title, bgColor, content }) => {
  const fontClasses = fontsStyles();
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.iconBox} style={{ backgroundColor: bgColor }}>
        {icon}
      </Box>
      <Box className={classes.contentBox}>
        <h4 className={fontClasses.h4}>{title}</h4>
        <p className={clsx(fontClasses.p, classes.contentText)}>{content}</p>
      </Box>
    </Box>
  );
};

export default ItemWithIcon;
