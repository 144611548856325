import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { ThemeProvider } from '@mui/material/styles';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Footer from './components/Footer';
import NavbarWrapper from './components/Navbar';
import WhatsappWidget from './components/WhatsappWidget';
import { PATHS } from './constants/index';
import PageNotFound from './pages/404';
import AboutUs from './pages/aboutUs';
import Careers from './pages/careers';
import Contact from './pages/contact';
import Home from './pages/home';
import Services from './pages/services';
import getTheme from './theme';

const App = () => {
  const theme = getTheme();
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  const httpLink = new HttpLink({ uri: process.env.REACT_APP_STRAPI_GRAPHQL });

  const authLink = new ApolloLink((operation, forward) => {
    // Use the setContext method to set the HTTP headers.
    operation.setContext({
      headers: {
        authorization: `Bearer ${process.env.REACT_APP_STRAPI_ACCESS_TOKEN}`,
      },
    });

    // Call the next link in the middleware chain.
    return forward(operation);
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink), // Chain it with the HttpLink
    cache: new InMemoryCache(),
  });
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <Router>
          <WhatsappWidget number={'+50687202621'} />
          <NavbarWrapper />
          <Routes>
            <Route exact path={PATHS.home} element={<Home />} />
            <Route exact path={PATHS.services} element={<Services />} />
            <Route exact path={PATHS.about} element={<AboutUs />} />
            <Route exact path={PATHS.careers} element={<Careers />} />
            <Route exact path={PATHS.contact} element={<Contact />} />
            <Route exact path={PATHS.pageNotFound} element={<PageNotFound />} />
          </Routes>
          <Footer />
        </Router>
      </ApolloProvider>
    </ThemeProvider>
  );
};

export default App;
