import GroupsIcon from '@mui/icons-material/Groups';
import PolicyIcon from '@mui/icons-material/Policy';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { Box, Grid } from '@mui/material';
import clsx from 'clsx';
import { useEffect } from 'react';

import ItemWithIcon from '../../components/ItemWithIcon';
import Metatags from '../../components/Metatags';
import NavigationComponent from '../../components/NavigationComponent';
import TeamCard from '../../components/TeamCard';
import { URL_IMAGES } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const AboutUs = () => {
  const classes = styles();
  const fontClasses = fontsStyles();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const WhoWeAre = () => {
    return (
      <Box className={classes.whoWeAreWrapper}>
        <Box className={classes.whoWeAreContentWrapper}>
          <Box>
            <Grid container columnSpacing={6} rowSpacing={{ xs: 2, sm: 2, md: 4, lg: 10 }}>
              <Grid item sm={12} md={12}>
                <h2 className={fontClasses.h2}>Quiénes Somos</h2>
                <p className={clsx(fontClasses.p)}>
                  Somos una startup de desarrollo de software ubicada en Limón, Costa Rica. Nuestro
                  objetivo es posicionarnos en la industria de la tecnología y luchar por ser
                  reconocidos por brindar servicios de calidad de manera transparente y eficiente.
                </p>
              </Grid>
              <Grid item sm={12} md={6}>
                <h2 className={fontClasses.h4}>Misión</h2>
                <p className={clsx(fontClasses.p, classes.headerSubtitle)}>
                  Nos apasiona el crecimiento local, para que a través de un servicio excepcional en
                  desarrollo de software podamos ampliar y facilitar las tareas de nuestros clientes
                  de manera eficiente, segura y confiable.
                </p>
              </Grid>
              <Grid item sm={12} md={6}>
                <h2 className={fontClasses.h4}>Visión</h2>
                <p className={clsx(fontClasses.p, classes.headerSubtitle)}>
                  Queremos impulsar el crecimiento de la provincia de Limón a través de las nuevas
                  tecnologías involucrando a las empresas para mejorar y agilizar procesos.
                </p>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  };
  const OurValues = () => {
    return (
      <Box className={classes.ourValuesWrapper}>
        <Box className={classes.ourValuesContentWrapper}>
          <Box>
            <h2 className={fontClasses.h2}>Creemos En Nuestros Valores</h2>
            <Box className={classes.ourValuesContent}>
              <Grid
                container
                columnSpacing={{ xs: 2, sm: 2, md: 4, lg: 6 }}
                rowSpacing={{ xs: 2, sm: 4, md: 4, lg: 10 }}
              >
                <Grid item sm={12} md={4}>
                  <ItemWithIcon
                    icon={<GroupsIcon style={{ color: '#4EADB3' }} />}
                    bgColor='#F1F9F9'
                    title='Trabajo En Equipo'
                    content='Trabajar eficazmente en equipo es algo que valoramos, por lo que nos aseguramos de que todo nuestro equipo sea capaz de trabajar con un grupo de personas mayor.'
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <ItemWithIcon
                    icon={<WorkspacePremiumIcon style={{ color: '#4EADB3' }} />}
                    bgColor='#F1F9F9'
                    title='Calidad'
                    content='Nos aseguramos de que todo lo relacionado con nosotros se entregue con la mejor calidad posible, para garantizar un gran producto a nuestros clientes.'
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <ItemWithIcon
                    icon={<PolicyIcon style={{ color: '#4EADB3' }} />}
                    bgColor='#F1F9F9'
                    title='Transparencia'
                    content='Desde el principio hasta el final, proporcionamos a nuestros clientes documentación y disponibilidad para compartir nuestro trabajo.'
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const OurTeam = () => {
    return (
      <Box className={classes.teamCardsWrapper}>
        <Box className={classes.teamCardsContentWrapper}>
          <h2 className={fontClasses.h2}>Conozca A Nuestro Equipo</h2>
          <p className={clsx(fontClasses.p, classes.headerSubtitle)}>
            Somos jóvenes desarrolladores con experiencia en aplicaciones web y modernas
            herramientas de desarrollo.
          </p>
          <Box className={classes.teamContent}>
            <Grid
              container
              columnSpacing={{ xs: 2, sm: 2, md: 4, lg: 6 }}
              rowSpacing={{ xs: 2, sm: 4, md: 4, lg: 6 }}
            >
              <Grid item md={6}>
                <TeamCard
                  name='Jean Vega Díaz'
                  image={URL_IMAGES.TEAM_MEMBER_JEAN}
                  linkedIn='https://www.linkedin.com/in/jeanvegad'
                  title='Ingeniero de Software'
                  content='Tengo facilidad de aprender y experimentar con nuevas tecnologías, especialmente las que me permiten adquirir conocimientos en otras áreas de aplicación gracias al trabajo en equipos multidisciplinarios.'
                />
              </Grid>
              <Grid item md={6}>
                <TeamCard
                  name='Joss Johnson'
                  image={URL_IMAGES.TEAM_MEMBER_JOSS}
                  linkedIn='https://www.linkedin.com/in/jossjohnson'
                  title='Ingeniero de Software'
                  content='Soy un ingeniero de software con 1+años desarrollando soluciones de software utilizando herramientas modernas, familiarizado con la integración continua y la entrega de productos de software. Capacidad de adaptación a cualquier circunstancia y capaz de trabajar en un equipo dinámico.'
                />
              </Grid>
              <Grid item md={6}>
                <TeamCard
                  name='Gilberth Ching Panton'
                  image={URL_IMAGES.TEAM_MEMBER_SAMUEL}
                  linkedIn='https://www.linkedin.com/in/gsching/'
                  title='Ingeniero de Software'
                  content='Soy ingeniero de software desde hace casi 2 años y eso me ha dado una gran experiencia en la optimización de tareas para clientes a través de soluciones en las mejores tecnologías del mercado. Siempre estoy dispuesto a aprender, enseñar y dar mi 100% a cualquier proyecto.'
                />
              </Grid>
              <Grid item md={6}>
                <TeamCard
                  name='Axel Mairena Arauz'
                  image={URL_IMAGES.TEAM_MEMBER_AXEL}
                  linkedIn='https://www.linkedin.com/in/axmairena/'
                  title='Ingeniero de Software'
                  content='Joven ingeniero de software, con ambición de ser mejor cada día. Con experiencia en desarrollo web utilizando herramientas y frameworks modernos.'
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Metatags
        title='Sealabs Technologies | Sobre nosotros'
        description='Somos una startup de desarrollo de software ubicada en Limón, Costa Rica. Nuestro objetivo es posicionarnos en la industria de la tecnología y luchar por ser reconocidos por brindar servicios de calidad de manera transparente y eficiente.'
      />
      <NavigationComponent
        labelPath1='Sobre Nosotros'
        title='Conoce Nuestro Equipo'
        bgColor='#F1F9F9'
      />
      <WhoWeAre />
      <OurValues />
      <OurTeam />
    </Box>
  );
};
export default AboutUs;
