import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { DIMENSIONS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  navigationWrapper: {
    backgroundColor: '#FFF9ED',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  navigationContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    paddingTop: '80px',
    paddingBottom: '80px',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      paddingTop: '25px',
      paddingBottom: '25px',
    },
  },
  navigationContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 10,
    flexDirection: 'row',
    [themeBreakpoints.down('sm')]: {
      gap: 8,
    },
  },
  iconRoute: {
    fontSize: '20px!important',
    [themeBreakpoints.down('sm')]: {
      fontSize: '18px!important',
    },
  },
  iconRoute2: {
    fontSize: '15px!important',
    [themeBreakpoints.down('sm')]: {
      fontSize: '13px!important',
    },
  },
  routeText: {
    color: '#151B25',
  },
  routeTextLink: {
    fontWeight: 400,
    '&:hover': {
      color: '#151B25',
    },
  },
  routeTile: {
    margin: 0,
    marginTop: 5,
  },
  pathLink: {
    textDecoration: 'none',
  },
}));

export default useStyles;
