import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  iconBox: {
    width: '70px',
    height: '70px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      width: '50px',
      height: '50px',
    },
  },
  contentBox: {
    marginTop: '30px',
    [themeBreakpoints.down('sm')]: {
      marginTop: '15px',
    },
  },
  contentText: {
    marginTop: '15px',
    textAlign: 'justify',
  },
}));

export default useStyles;
