import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import GitHubIcon from '@mui/icons-material/GitHub';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { URL_IMAGES } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import FooterButton from '../FooterButton';
import useStyles from './styles';

export default function FooterWrapper(props) {
  const classes = useStyles();
  const fontClasses = fontsStyles();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  // const [hubspotutk, setHubspotutk] = useState(Cookies.get('hubspotutk'));
  const [hubspotutk] = useState(Cookies.get('hubspotutk'));
  const [contactForm, setContactForm] = useState({
    email: '',
  });

  const handleCloseErrorMsg = () => {
    setErrorMessage(false);
  };

  const handleCloseSuccessMsg = () => {
    setSuccessMessage(false);
  };

  const validateEmail = (mail) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    return regex.test(mail);
  };

  const handleValidateEmail = () => {
    setValidEmail(validateEmail(contactForm.email));
  };

  const handleSetField = (field, value) => {
    setContactForm({ ...contactForm, [field]: value });
  };

  const onSubmitForms = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    sendData();
  };

  const clearFields = async (e) => {
    setContactForm({
      email: '',
    });
  };

  const sendData = async () => {
    try {
      const jsonData = {
        fields: [
          {
            name: 'email',
            value: contactForm.email,
          },
        ],
        context: {
          hutk: hubspotutk,
          pageUri: 'https://sealabs.cr',
          pageName: 'Sealabs Technologies - Contact',
        },
        legalConsentOptions: {
          consent: {
            consentToProcess: true,
            text: 'I agree to allow Sealabs Technologies to store and process my personal data.',
            communications: [
              {
                value: true,
                subscriptionTypeId: 51089624,
                text: 'I agree to receive marketing communications from Sealabs Technologies .Sealabs respects your privacy. Your email will be used to provide information about our services. You can unsubscribe from our newsletter at any time.',
              },
            ],
          },
        },
      };
      const headers = {
        'Content-Type': 'application/json',
      };
      await axios.post(
        `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_HUBSPOT_PORTAL_ID}/${process.env.REACT_APP_HUBSPOT_NEWLETTER_FORM_ID}`,
        jsonData,
        { headers },
      );
      setSuccessMessage(true);
      clearFields();
      setSubmitLoading(false);
    } catch (error) {
      setErrorMessage(true);
      setSubmitLoading(false);
    }
    // const jsonData = {
    //   fields: [
    //     {
    //       name: 'email',
    //       value: contactForm.email,
    //     },
    //   ],
    //   context: {
    //     hutk: hubspotutk,
    //     pageUri: 'https://sealabs.cr',
    //     pageName: 'Sealabs Technologies - Contact',
    //   },
    //   legalConsentOptions: {
    //     consent: {
    //       consentToProcess: true,
    //       text: 'I agree to allow Sealabs Technologies to store and process my personal data.',
    //       communications: [
    //         {
    //           value: true,
    //           subscriptionTypeId: 51089624,
    //           text: 'I agree to receive marketing communications from Sealabs Technologies .Sealabs respects your privacy. Your email will be used to provide information about our services. You can unsubscribe from our newsletter at any time.',
    //         },
    //       ],
    //     },
    //   },
    // };

    // const headers = {
    //   'Content-Type': 'application/json',
    // };

    // axios
    //   .post(
    //     `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_HUBSPOT_PORTAL_ID}/${process.env.REACT_APP_HUBSPOT_NEWLETTER_FORM_ID}`,
    //     jsonData,
    //     { headers },
    //   )
    //   .then((response) => {
    //     setSuccessMessage(true);
    //     clearFields();
    //     setSubmitLoading(false);
    //   })
    //   .catch((error) => {
    //     setErrorMessage(true);
    //     setSubmitLoading(false);
    //   });
  };

  return (
    <Box className={classes.footerWrapper}>
      <Box className={classes.footerContent}>
        <Grid container rowSpacing={{ xs: 4, md: 8 }}>
          <Grid item sm={12} md={12} lg={6}>
            <h4 className={clsx(fontClasses.h4)}>Suscríbase a nuestro boletín</h4>
            <Box>
              <Box className={classes.newsLetterBox}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <TextField
                      fullWidth
                      size='small'
                      value={contactForm.email || ''}
                      onChange={(event) => handleSetField('email', event.target.value)}
                      id='form_name'
                      label='Correo'
                      onKeyUp={handleValidateEmail}
                      error={!validEmail && contactForm.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {submitLoading && <CircularProgress style={{ color: '#0077FF' }} />}
                    {!submitLoading && (
                      <FooterButton
                        label='Enviar'
                        url={''}
                        onclick={onSubmitForms}
                        disabled={!validateEmail(contactForm.email)}
                        icon={
                          <ArrowForwardIosIcon
                            className={classes.buttonIcon}
                            style={{ color: !validateEmail(contactForm.email) ? 'gray' : 'white' }}
                          />
                        }
                      />
                    )}
                  </Grid>
                </Grid>
                <p className={classes.newsletterMessage}>
                  Sealabs respeta su privacidad. Su correo electrónico se utilizará para
                  proporcionar información sobre nuestros servicios. Puede darse de baja de nuestro
                  boletín de noticias en cualquier momento.
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <h4 className={clsx(fontClasses.h4)}>Compañia</h4>
            <Box className={classes.footerItemBox}>
              <p className={classes.footerLinks}>
                <Link to={'/services'} className={clsx(classes.footerLinks)}>
                  Servicios
                </Link>
              </p>
              <p className={classes.footerLinks}>
                <Link to={'/about'} className={clsx(classes.footerLinks)}>
                  Sobre nosotros
                </Link>
              </p>
              <p className={classes.footerLinks}>
                <Link to={'/careers'} className={clsx(classes.footerLinks)}>
                  Empleos
                </Link>
              </p>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <h4 className={clsx(fontClasses.h4)}>Contáctenos</h4>
            <Box className={classes.footerItemBox}>
              <p className={classes.footerLinks}>
                <Link to={'/contact'} className={clsx(classes.footerLinks)}>
                  Contacto
                </Link>
              </p>
              <p className={classes.footerLinks}>
                <a
                  href={
                    'https://www.google.com/maps/place/Lim%C3%B3n/@9.9918421,-83.0602886,14z/data=!3m1!4b1!4m5!3m4!1s0x8fa705367150d903:0x24d864fd050c815f!8m2!3d9.9913106!4d-83.0415078'
                  }
                  className={clsx(classes.footerLinks)}
                >
                  Puerto Limón , Costa Rica
                </a>
              </p>
              <p className={classes.footerLinks}>
                <a href={'tel:+50687202621'} className={clsx(classes.footerLinks)}>
                  (+506) 8720 2621
                </a>
              </p>
              <p className={classes.footerLinks}>
                <a href={'mailto: hello@sealabs.cr'} className={clsx(classes.footerLinks)}>
                  hello@sealabs.cr
                </a>
              </p>
            </Box>
          </Grid>
        </Grid>
        <hr className={classes.divisorLine} />
        <Grid container>
          <Grid item md={4} xs={12}>
            <Box className={classes.footerLogoBox}>
              <Link to='/'>
                <img
                  src={URL_IMAGES.URL_LOGO_SEALABS_DARK}
                  alt='Sealabs logo'
                  className={classes.footerLogo}
                />
              </Link>
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box className={classes.footerSocialBox}>
              <a href='https://www.linkedin.com/company/sealabscr' target='_blank' rel='noreferrer'>
                <p className={classes.socialIcon}>
                  <LinkedInIcon style={{ fontSize: '30px' }} />
                </p>
              </a>
              <a href='https://www.instagram.com/sealabscr' target='_blank' rel='noreferrer'>
                <p className={classes.socialIcon}>
                  <InstagramIcon style={{ fontSize: '30px' }} />
                </p>
              </a>
              <a href='https://github.com/sealabscr' target='_blank' rel='noreferrer'>
                <p className={classes.socialIcon}>
                  <GitHubIcon style={{ fontSize: '30px' }} />
                </p>
              </a>
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <p className={classes.footerRights}>
              Derechos reservados Sealabs Technologies {new Date().getFullYear()}
            </p>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={successMessage}
        onClose={handleCloseSuccessMsg}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert variant='filled' severity='success' sx={{ width: '100%' }}>
          Gracias por suscribirte.
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorMessage}
        onClose={handleCloseErrorMsg}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert variant='filled' severity='error' sx={{ width: '100%' }}>
          Lo siento, ha ocurrido algo. Por favor, inténtelo de nuevo.
        </Alert>
      </Snackbar>
    </Box>
  );
}
