import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { DIMENSIONS } from '../../constants/';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  navbarWrapper: {
    backgroundColor: '#EEF7FE',
    height: '100px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 3000,
    position: 'sticky',
    top: 0,
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  navBarShadow: {
    boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.1);',
  },
  navbarContent: {
    width: DIMENSIONS.MAIN_WRAPPER,
    display: 'flex',
    alignItems: 'center',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      padding: '20px 10px',
    },
  },
  navBarLogo: {
    width: '150px',
    [themeBreakpoints.down('sm')]: {
      width: '150px',
      paddingTop: '3px',
    },
  },
  navbarHamburgerMenuBox: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  navbarItemsBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '30px',
    [themeBreakpoints.down('md')]: {
      gap: '20px',
    },
  },
  navbarItem: {
    fontFamily: ['Poppins'].join(','),
    fontSize: '16px',
    fontWeight: '400',
    textDecoration: 'none',
    color: '#151B25',
    '&:hover': {
      fontWeight: '600',
      textDecoration: 'none',
      color: '#151B25',
    },
  },
  navbarItemActive: {
    fontFamily: ['Poppins'].join(','),
    fontSize: '16px',
    fontWeight: '600',
    textDecoration: 'none',
    color: '#151B25',
    '&:hover': {
      fontWeight: '600',
      textDecoration: 'none',
      color: '#151B25',
    },
  },
  listDrawerBox: {
    width: '250px',
    marginTop: '80px',
  },
}));

export default useStyles;
