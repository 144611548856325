import CloseIcon from '@mui/icons-material/Close';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import clsx from 'clsx';
import { useState } from 'react';

import { URL_IMAGES } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import useStyles from './styles';

const WhatsappWidget = ({ number }) => {
  const classes = useStyles();
  const fontClasses = fontsStyles();
  const isMobile = function isMobile() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      return true;
    return false;
  };

  const sendMessage = (number) => {
    let apilink = 'http://';
    apilink += isMobile() ? 'api' : 'web';
    apilink +=
      '.whatsapp.com/send?phone=' +
      number +
      '&text=' +
      encodeURI(
        'Hola, estoy interesado/a en obtener más información sobre sus productos/servicios. ¿Podrían ayudarme? ¡Gracias!',
      ) +
      '&app_absent=0';
    window.open(apilink);
  };

  const [show, setShow] = useState(false);

  return (
    <Box>
      <Grow in={show}>
        <Box className={classes.cardBox}>
          <Box className={classes.cardContentBox}>
            <button className={classes.widgetCloseButton} onClick={() => setShow(false)}>
              <CloseIcon style={{ fontSize: '20px', color: '#444655' }} />
            </button>
            <img
              className={classes.widgetImage}
              src={URL_IMAGES.URL_LOGO_SEALABS_DARK}
              alt='sealabs logo'
            />
            <Box>
              <p className={clsx(fontClasses.p, classes.widgetTitle)}>¿Como te podemos ayudar?</p>
              <p className={clsx(fontClasses.p, classes.widgetMessage)}>
                Tenemos a su disposición un canal de WhatsApp para que pueda recibir una atencion
                personalizada.
              </p>
            </Box>
            <Box className={classes.chatButton} onClick={() => sendMessage(number)}>
              <p className={classes.chatButtonText}>Iniciar conversación</p>
            </Box>
          </Box>
        </Box>
      </Grow>
      <Box className={classes.widgetMainButton} onClick={() => setShow(!show)}>
        <WhatsAppIcon style={{ fontSize: '35px' }} />
      </Box>
    </Box>
  );
};

export default WhatsappWidget;
