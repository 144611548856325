import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { DIMENSIONS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  notFoundWrapper: {
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  notFoundContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '25px',
    paddingBottom: '25px',
    margin: '150px 0 150px 0',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      margin: '50px 0 50px 0',
      width: '90%',
      paddingTop: '50px',
      paddingBottom: '50px',
    },
  },
  notFoundContent: {
    width: '60%',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  textAlign: {
    width: '100%',
    textAlign: 'center',
    [themeBreakpoints.down('sm')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  vectorImgBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  vectorImg: {
    display: 'flex',
    justifyContent: 'center',
    width: '35%',
    [themeBreakpoints.down('sm')]: {
      width: '50%',
    },
  },
  headerButtonsBox: {
    display: 'flex',
    justifyContent: 'center',
    gap: 20,
    marginTop: '30px',
  },
  buttonIcon: {
    color: 'white',
  },
}));

export default styles;
