import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import WorkOutlineRoundedIcon from '@mui/icons-material/WorkOutlineRounded';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { URL_IMAGES } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import useStyles from './styles';

const navBarItems = [
  {
    label: 'Servicios',
    path: '/services',
    icon: <SettingsSuggestOutlinedIcon />,
  },
  {
    label: 'Sobre Nosotros',
    path: '/about',
    icon: <HomeWorkOutlinedIcon />,
  },
  {
    label: 'Empleos',
    path: '/careers',
    icon: <WorkOutlineRoundedIcon />,
  },
  {
    label: 'Contacto',
    path: '/contact',
    icon: <AccountCircleOutlinedIcon />,
  },
];

export default function NavbarWrapper(props) {
  const classes = useStyles();
  const fontClasses = fontsStyles();
  const location = useLocation();
  const [pathname, setPathname] = useState('');
  const [drawer, setDrawer] = useState(false);
  const [shadow, setShadow] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  const openDrawer = () => {
    setDrawer(true);
  };

  const closeDrawer = () => {
    setDrawer(false);
  };

  useEffect(() => {
    setPathname(location.pathname);
    setDrawer(false);
  }, [location]);

  useEffect(() => {
    if (pathname !== '/') setShadow(true);
    else {
      if (scrollY !== 0) setShadow(true);
      else setShadow(false);
    }
  }, [pathname, scrollY]);

  function logit() {
    setScrollY(window.pageYOffset);
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener('scroll', logit);
    };
  });

  return (
    <Box className={clsx(classes.navbarWrapper, shadow ? classes.navBarShadow : '')}>
      <Box className={classes.navbarContent}>
        <Grid container>
          <Grid item md={2}>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <Box
                className={classes.navbarHamburgerMenuBox}
                sx={{ display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none' } }}
              >
                <IconButton aria-label='delete' onClick={openDrawer}>
                  <MenuIcon />
                </IconButton>
              </Box>
              <Box className={classes.navBarLogo}>
                <Link to='/'>
                  <img
                    style={{ width: '100%' }}
                    src={URL_IMAGES.URL_LOGO_SEALABS_DARK}
                    alt='sealabs logo'
                  />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            md={10}
            className={classes.navbarItemsBox}
            sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' } }}
          >
            {navBarItems.map((item) => (
              <Link
                key={item.path}
                className={pathname === item.path ? classes.navbarItemActive : classes.navbarItem}
                to={item.path}
              >
                {item.label}
              </Link>
            ))}
          </Grid>
        </Grid>
        <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none' } }}>
          <Drawer anchor='left' open={drawer} onClose={closeDrawer}>
            <Box className={classes.listDrawerBox}>
              <List>
                {navBarItems.map((item, index) => (
                  <ListItem key={index} disablePadding>
                    <Link to={item.path} style={{ width: '100%', textDecoration: 'none' }}>
                      <ListItemButton
                        sx={{ backgroundColor: pathname === item.path ? '#EFF5FF' : 'white' }}
                        t
                      >
                        <ListItemIcon className={classes.listItemIcon} sx={{ minWidth: '40px' }}>
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          disableTypography
                          primary={
                            <p
                              className={fontClasses.p}
                              style={{ color: '#151B25', fontWeight: 500 }}
                            >
                              {item.label}
                            </p>
                          }
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Drawer>
        </Box>
      </Box>
    </Box>
  );
}
