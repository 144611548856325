import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import breakpoints from '../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const fontsStyles = makeStyles((theme) => ({
  h1: {
    fontSize: 40,
    fontWeight: 600,
    color: '#151B25',
    lineHeight: '50px',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 28,
      lineHeight: '33px',
    },
  },
  h2: {
    fontSize: 32,
    fontWeight: 600,
    color: '#151B25',
    lineHeight: '42px',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 26,
      lineHeight: '36px',
    },
  },
  h3: {
    fontSize: 28,
    fontWeight: 600,
    color: '#151B25',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  h4: {
    fontSize: 24,
    fontWeight: 600,
    color: '#151B25',
    lineHeight: '36px',
    margin: '0px',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  p: {
    margin: 0,
    fontSize: 16,
    fontWeight: 400,
    color: '#444655',
    lineHeight: '24px',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      margin: 0,
    },
  },
  colorBlue: {
    color: '#0077FF',
  },
  links: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  primaryButtonText: {
    margin: 0,
    fontSize: 16,
    fontWeight: 400,
    color: 'white',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      margin: 0,
    },
  },
  secondaryButtonText: {
    margin: 0,
    fontSize: 16,
    fontWeight: 500,
    color: '#151B25',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      margin: 0,
    },
  },
  footerButtonText: {
    margin: 0,
    fontSize: 16,
    fontWeight: 400,
    color: 'white',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      margin: 0,
    },
  },
  footerButtonTextDisabled: {
    margin: 0,
    fontSize: 16,
    fontWeight: 400,
    color: 'gray',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      margin: 0,
    },
  },
  titleInfo: {
    width: '85%',
    [themeBreakpoints.down('md')]: {
      width: '100%',
    },
    [themeBreakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'justify',
    },
  },
  a: {
    color: '#0077FF',
    textDecoration: 'none',
  },
  aGreen: {
    color: '#4EADB3',
    textDecoration: 'none',
  },
}));

export default fontsStyles;
