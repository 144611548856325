import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

import fontsStyles from '../../styles/fontStyles';
import useStyles from './styles';

const CustomPrimaryButton = ({ label, icon, url, disabled, onClick }) => {
  const fontClasses = fontsStyles();
  const classes = useStyles();

  return (
    <Link to={url} style={{ textDecoration: 'none' }}>
      <button
        className={disabled ? classes.buttonMainStyleDisabled : classes.buttonMainStyle}
        onClick={onClick || null}
        disabled={disabled}
      >
        <p
          className={disabled ? fontClasses.footerButtonTextDisabled : fontClasses.footerButtonText}
        >
          {label}
        </p>
        <Box className={classes.iconButtonBox}>{icon}</Box>
      </button>
    </Link>
  );
};

export default CustomPrimaryButton;
