import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DevicesIcon from '@mui/icons-material/Devices';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import WebIcon from '@mui/icons-material/Web';
import { Box, Grid } from '@mui/material';
import clsx from 'clsx';
import { useEffect } from 'react';

import CustomPrimaryButton from '../../components/CustomPrimaryButton';
import CustomSecondaryButton from '../../components/CustomSecondaryButton';
import ItemWithIcon from '../../components/ItemWithIcon';
import Metatags from '../../components/Metatags';
import { URL_IMAGES } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const Home = () => {
  const classes = styles();
  const fontClasses = fontsStyles();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const Header = () => {
    return (
      <Box className={classes.headerWrapper}>
        <Box className={classes.headerContentWrapper}>
          <Box className={classes.headerContent}>
            <h1 className={fontClasses.h1}>
              Mejore Su Negocio <br /> Mediante la Transformación <br /> Digital{' '}
            </h1>
            <p className={clsx(fontClasses.p, classes.headerSubtitle)}>
              Construimos soluciones de software personalizadas que se adaptan a las necesidades de
              su empresa, garantizando la calidad y la transparencia.
            </p>
            <Box className={classes.headerButtonsBox}>
              <CustomPrimaryButton
                label='Expolere Nuestros Servicios'
                url={'/services'}
                icon={<ArrowForwardIcon className={classes.buttonIcon} />}
              />
              <CustomSecondaryButton
                label='Contáctenos'
                url={'/contact'}
                icon={<ArrowForwardIcon className={classes.buttonIconSecondary} />}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const BussinesProblems = () => {
    return (
      <Box className={classes.bussinesWrapper}>
        <Box className={classes.bussinesContentWrapper}>
          <Box className={classes.bussinesHeaderContent}>
            <h2 className={fontClasses.h2}>Solucionamos Problemas Empresariales</h2>
            <p className={clsx(fontClasses.p, fontClasses.titleInfo)}>
              En Sealabs tratamos de llevar las ideas de nuestros clientes para ayudarles a innovar
              en su negocio y afianzarse en el mundo digital.{' '}
              <a className={fontClasses.a} href='/services'>
                Explore nuestros servicios
              </a>
            </p>
          </Box>
          <Grid container columnSpacing={12} rowSpacing={{ xs: 5, sm: 5, md: 10, lg: 10 }}>
            <Grid item sm={12} md={6} lg={4}>
              <ItemWithIcon
                title={'Sitios Web'}
                content={
                  'Impulsamos tu idea de negocio desarrollando una web a medida y adaptable a todo tipo de dispositivos.'
                }
                bgColor='#F1F9F9'
                icon={<WebIcon style={{ color: '#4EADB3' }} />}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              <ItemWithIcon
                title={'Aplicaciones Móviles'}
                content={
                  'Desarrollamos aplicaciones móviles de acuerdo a tus necesidades para optimizar las tareas de tus clientes y colaboradores.'
                }
                bgColor='#FFF9ED'
                icon={<DevicesIcon style={{ color: '#FFA81A' }} />}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              <ItemWithIcon
                title={'Comercio Electrónico'}
                content={
                  'Personalice o cree su sitio web de comercio electrónico aplicando el mejor rendimiento y características para su negocio'
                }
                bgColor='#FFF5F6'
                icon={<LocalMallIcon style={{ color: '#FF7994' }} />}
              />
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              lg={4}
              sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'none', xl: 'none' } }}
            >
              <ItemWithIcon
                title={'Consulting Services'}
                content={
                  'Provide solutions for Information Technology services problems a business can face in maintenance, development, and management.'
                }
                bgColor='#EEF7FE'
                icon={<QuestionAnswerIcon style={{ color: '#0077FF' }} />}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  const ProjectManagement = () => {
    return (
      <Box className={classes.projectMangWrapper}>
        <Box className={classes.projectMangContentWrapper}>
          <Box className={classes.insideContent}>
            <Box className={classes.pmText}>
              <h2 className={fontClasses.h2} style={{ textAlign: 'center' }}>
                Ciclo de Vida De La Gestión De Proyectos
              </h2>
              <p className={clsx(fontClasses.p, classes.pargrahpCenter)}>
                Ayudaremos a que su negocio crezca creando software personalizable y de alta calidad
                para una eficiente agilización de procesos..
              </p>
            </Box>
            <Box
              sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' } }}
            >
              <Box className={classes.pmImage}>
                <img
                  style={{ width: '80%' }}
                  src={URL_IMAGES.VECTOR_PM_SECTION}
                  alt='Project managnmet life cicle'
                />
              </Box>
            </Box>
            <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none' } }}>
              <Box className={classes.pmImage}>
                <img
                  style={{ width: '80%' }}
                  src={URL_IMAGES.VECTOR_PM_SECTION_VERTICAL}
                  alt='Project managnmet life cicle'
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const StrategicLocation = () => {
    return (
      <Box className={classes.strategicLocationWrapper}>
        <Box className={classes.strategicLocationContentWrapper}>
          <Box className={clsx(classes.insideContent, classes.strategicLocationContent)}>
            <Box>
              <h2 className={clsx(fontClasses.h2, classes.stLocationTitleText)}>
                Ubicados En Un Lugar Estratégico
              </h2>
              <p className={clsx(fontClasses.p, classes.stLocationText)}>
                Elegimos Limón como nuestra ubicación porque estamos dispuestos a desarrollar la
                industria tecnológica alrededor de la ciudad, involucrando a las pequeñas empresas
                para ayudarlas a crecer y crear una cadena de procesos para nuestros clientes para
                mejorar su eficiencia.{' '}
                <a className={fontClasses.aGreen} href='/about'>
                  Vea más acerca de nosotros
                </a>
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const WorkWithUs = () => {
    return (
      <Box className={classes.workWithUsWrapper}>
        <Box className={classes.workWithUsContentWrapper}>
          <Box>
            <Grid container>
              <Grid item md={7} sm={12}>
                <Box>
                  <h1 className={clsx(fontClasses.h2)}>Empieza a trabajar con nosotros </h1>
                  <p className={clsx(fontClasses.p)}>
                    Nuestro principal objetivo es proporcionar a nuestros clientes el mejor
                    rendimiento del servicio y proponer soluciones para su transformación digital.
                  </p>
                </Box>
                <Box className={classes.headerButtonsBox}>
                  <CustomPrimaryButton
                    label='Contáctenos'
                    url={'/contact'}
                    icon={<ArrowForwardIcon className={classes.buttonIcon} />}
                  />
                  <CustomSecondaryButton
                    label='Linkedin'
                    url={'https://www.linkedin.com/company/sealabscr/'}
                    icon={<LinkedInIcon className={classes.buttonIconSecondary} />}
                  />
                </Box>
              </Grid>
              <Grid
                md={1}
                sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' } }}
              />
              <Grid
                item
                md={4}
                sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' } }}
              >
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    style={{ width: '100%' }}
                    src={URL_IMAGES.VECTOR_DEVICES_WHITE}
                    alt='Project managnmet life cicle'
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Metatags
        title='Sealabs Technologies'
        description='Construimos soluciones de software personalizadas que se adaptan a las necesidades de su empresa, garantizando la calidad y la transparencia.'
      />
      <Header />
      <BussinesProblems />
      <StrategicLocation />
      <ProjectManagement />
      <WorkWithUs />
    </Box>
  );
};

export default Home;
