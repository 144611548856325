import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import { Box, Grid } from '@mui/material';
import clsx from 'clsx';
import { useEffect } from 'react';

import CardItem from '../../components/CardItem';
import ItemWithIcon from '../../components/ItemWithIcon';
import Metatags from '../../components/Metatags';
import NavigationComponent from '../../components/NavigationComponent';
import { URL_IMAGES } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const Services = () => {
  const classes = styles();
  const fontClasses = fontsStyles();

  useEffect(() => {
    console.log(window);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const ServicesComponent = () => {
    return (
      <Box className={classes.servicesWrapper}>
        <Box className={classes.servicesContentWrapper}>
          <Box>
            <h2 className={fontClasses.h2}>Nuestros servicios</h2>
            <p className={clsx(fontClasses.p, fontClasses.titleInfo)}>
              A través de nuestros diferentes servicios de calidad, podemos guiar su negocio para
              mejorar en diferentes áreas. En Sealabs tratamos de tomar las ideas de nuestros
              clientes para ayudarles a innovar su negocio y establecerse en el mundo digital.
            </p>
            <Box className={classes.servicesBoxContent}>
              <Grid container columnSpacing={12} rowSpacing={{ xs: 5, sm: 5, md: 10, lg: 10 }}>
                <Grid item sm={12} md={6} lg={4}>
                  <ItemWithIcon
                    title='Ingenieros De Software'
                    content='Con ingenieros de calidad, nos aseguramos de que la construcción de sus productos o ideas se haga de la mejor manera.'
                    bgColor='#FFF9ED'
                    icon={<EngineeringOutlinedIcon style={{ color: '#FFA81A' }} />}
                  />
                </Grid>
                <Grid item sm={12} md={6} lg={4}>
                  <ItemWithIcon
                    icon={<FormatListBulletedOutlinedIcon style={{ color: '#FFA81A' }} />}
                    title='Gestores De Proyectos'
                    content='En Sealabs nos aseguramos de contar con profesionales que le guiarán a lo largo del ciclo de vida de su proyecto, desde su inicio hasta su finalización.'
                    bgColor='#FFF9ED'
                  />
                </Grid>
                <Grid item sm={12} md={6} lg={4}>
                  <ItemWithIcon
                    icon={<TaskOutlinedIcon style={{ color: '#FFA81A' }} />}
                    title='Ingenieros De QA'
                    content='Cuidamos la calidad de cada uno de los productos o proyectos de nuestros clientes. Asegurando así un resultado final en las mejores condiciones posibles.'
                    bgColor='#FFF9ED'
                  />
                </Grid>
                <Grid item sm={12} md={6} lg={4}>
                  <ItemWithIcon
                    icon={<AccountTreeIcon style={{ color: '#FFA81A' }} />}
                    title='DevOps & Arquitectura'
                    content='Nos encargamos de automatizar los procesos y la infraestructura en la nube a la vez que garantizamos la entrega continua y la integración perfecta para llevar su producto al mercado digital.'
                    bgColor='#FFF9ED'
                  />
                </Grid>
                <Grid item sm={12} md={6} lg={4}>
                  <ItemWithIcon
                    icon={<DesignServicesIcon style={{ color: '#FFA81A' }} />}
                    title='Diseños de UX/UI'
                    content='Nos encargamos de diseñar la mejor experiencia para satisfacer las necesidades de nuestros clientes. Tanto si necesita una aplicación diseñada desde cero como una actualización de una interfaz existente, nuestro equipo se asegura de que se cumplan los objetivos del proyecto.'
                    bgColor='#FFF9ED'
                  />
                </Grid>
                <Grid item sm={12} md={6} lg={4}>
                  <ItemWithIcon
                    icon={<SupportAgentIcon style={{ color: '#FFA81A' }} />}
                    title='Soporte'
                    content='Podemos dar soporte a diferentes necesidades y problemas, ayudando a nuestros clientes con un tratamiento de máxima calidad.'
                    bgColor='#FFF9ED'
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const WhatWeDoComponent = () => {
    return (
      <Box className={classes.whatWeDoWrapper}>
        <Box className={classes.whatWeDoContentWrapper}>
          <Box>
            <h2 className={fontClasses.h2}>¿Qué hacemos?</h2>
            <p className={clsx(fontClasses.p, fontClasses.titleInfo)}>
              Estos son algunos ejemplos de lo que ofrecemos a nuestros clientes.
            </p>
            <Box className={classes.servicesBoxContent}>
              <Grid container columnSpacing={4} rowSpacing={{ xs: 2, sm: 2, md: 4, lg: 4 }}>
                <Grid item sm={12} md={6}>
                  <CardItem
                    icon={<EngineeringOutlinedIcon className={classes.buttonIcon} />}
                    title='Sitios Web'
                    content='Impulsamos la idea de tu negocio desarrollando un sitio web a la medida y adaptable a todo tipo de dispositivos.'
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <CardItem
                    icon={<FormatListBulletedOutlinedIcon className={classes.buttonIcon} />}
                    title='Aplicaciones Web/Móviles'
                    content='Desarrollamos aplicaciones web o móviles según tus necesidades para optimizar las tareas de tus clientes y colaboradores.'
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <CardItem
                    icon={<TaskOutlinedIcon className={classes.buttonIcon} />}
                    title='Comercio Electrónico'
                    content='Personalice o cree su sitio web de comercio electrónico aplicando el mejor rendimiento y características para su negocio.'
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <CardItem
                    icon={<AccountTreeIcon className={classes.buttonIcon} />}
                    title='Arquitectura'
                    content='Integre servicios y microservicios para sus sistemas de información alineándolos con sus objetivos comerciales.'
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <CardItem
                    icon={<DesignServicesIcon className={classes.buttonIcon} />}
                    title='Servicios de Consultoría'
                    content='Proporcionamos soluciones para los problemas de TI que una empresa puede enfrentar en mantenimiento, desarrollo y administración.'
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <CardItem
                    icon={<SupportAgentIcon className={classes.buttonIcon} />}
                    title='Marketing & SEO'
                    content='Proporcione a su empresa la oportunidad de comercializar su marca y expandir sus servicios a un publico meta.'
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const ThecnologiesComponent = () => {
    return (
      <Box className={classes.techWrapper}>
        <Box className={classes.techWrapperContentWrapper}>
          <Box>
            <h2 className={fontClasses.h2}>Nos Adaptamos A Las Nuevas Tecnologías</h2>
            <p className={clsx(fontClasses.p, fontClasses.titleInfo)}>
              En Sealabs ofrecemos un extenso stack de tecnologías y herramientas para brindar
              mejoras y crear soluciones para su negocio.
            </p>
            <Box className={classes.techBoxContent}>
              <Box
                sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' } }}
              >
                <Box className={classes.pmImage}>
                  <img
                    style={{ width: '80%' }}
                    src={URL_IMAGES.VECTOR_TECH_HORIZONTAL}
                    alt='Technologies'
                  />
                </Box>
              </Box>
              <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none' } }}>
                <Box className={classes.pmImage}>
                  <img
                    style={{ width: '80%' }}
                    src={URL_IMAGES.VECTOR_TECH_VERTICAL}
                    alt='Technologies'
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Metatags
        title='Sealabs Technologies | Servicios'
        description='A través de nuestros diferentes servicios de calidad, podemos guiar su negocio para mejorar en diferentes áreas.'
      />
      <NavigationComponent
        labelPath1='Servicios'
        title='Resolvemos Problemas Empresariales'
        bgColor='#FFF9ED'
      />
      <ServicesComponent />
      <WhatWeDoComponent />
      <ThecnologiesComponent />
    </Box>
  );
};

export default Services;
