import { gql, useMutation } from '@apollo/client';
import InputLabel from '@material-ui/core/InputLabel';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import CustomPrimaryButton from '../../components/CustomPrimaryButton';
import Metatags from '../../components/Metatags';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const ADD_CONTACT_FORM_MUTATION = gql`
  mutation AddContactForm(
    $email: String!
    $name: String!
    $company: String!
    $message: String!
    $country: String!
  ) {
    createContactForm(
      data: { email: $email, name: $name, company: $company, message: $message, country: $country }
    ) {
      data {
        id
        attributes {
          email
          name
          company
          message
          country
        }
      }
    }
  }
`;
const countryList = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas (the)',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory (the)',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands (the)',
  'Central African Republic (the)',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands (the)',
  'Colombia',
  'Comoros (the)',
  'Congo (the Democratic Republic of the)',
  'Congo (the)',
  'Cook Islands (the)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  "Côte d'Ivoire",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic (the)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands (the) [Malvinas]',
  'Faroe Islands (the)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories (the)',
  'Gabon',
  'Gambia (the)',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See (the)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea (the Democratic People's Republic of)",
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic (the)",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands (the)',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia (Federated States of)',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands (the)',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger (the)',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands (the)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines (the)',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of North Macedonia',
  'Romania',
  'Russian Federation (the)',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan (the)',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands (the)',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates (the)',
  'United Kingdom of Great Britain and Northern Ireland (the)',
  'United States Minor Outlying Islands (the)',
  'United States of America (the)',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Åland Islands',
];

const Contact = () => {
  const classes = styles();
  const fontClasses = fontsStyles();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const ServicesArea = () => {
    return (
      <Box className={classes.servicesWrapper}>
        <Box className={classes.servicesContentWrapper}>
          <Box>
            <h2 className={fontClasses.h2}>¿Cómo Podemos Ayudarle?</h2>
            <p className={clsx(fontClasses.p, classes.headerSubtitle)}>
              Estaremos encantados de ayudarle y le agradeceremos que nos elija para cualquier
              necesidad empresarial a la que quiera dirigirse. Utilice la parte inferior para
              explorar nuestros servicios.
            </p>
            <Box className={classes.headerButtonsBox}>
              <CustomPrimaryButton
                label='Explore Nuestros Servicios'
                url={'/services/'}
                icon={<ArrowForwardIcon className={classes.buttonIcon} />}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const SendEmail = () => {
    const [recaptchaValue, setRecaptchaValue] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [validEmail, setValidEmail] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [addContactForm, { data, loading, error }] = useMutation(ADD_CONTACT_FORM_MUTATION);
    const [contactForm, setContactForm] = useState({
      name: '',
      lastName: '',
      country: 'Costa Rica',
      email: '',
      companyName: '',
      additionalComments: '',
    });

    const handleCloseErrorMsg = () => {
      setErrorMessage(false);
    };

    const handleCloseSuccessMsg = () => {
      setSuccessMessage(false);
    };

    const validateEmail = (mail) => {
      const regex =
        /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i;
      return regex.test(mail);
    };

    const handleValidateEmail = () => {
      setValidEmail(validateEmail(contactForm.email));
    };

    const handleSetField = (field, value) => {
      setContactForm({ ...contactForm, [field]: value });
    };

    const onSubmitForms = async (e) => {
      e.preventDefault();
      setSubmitLoading(true);
      sendData();
    };

    const clearFields = async (e) => {
      setContactForm({
        name: '',
        lastName: '',
        country: 'Costa Rica',
        email: '',
        companyName: '',
        additionalComments: '',
      });
    };

    useEffect(() => {
      if (error && !loading) {
        setErrorMessage(true);
        setSubmitLoading(false);
      }
      if (data && !error && !loading) {
        setSuccessMessage(true);
        clearFields();
        setSubmitLoading(false);
      }
    }, [data]);

    const sendData = async () => {
      const json = {
        email: contactForm.email,
        name: `${contactForm.name} ${contactForm.lastName}`,
        company: contactForm.companyName,
        message: contactForm.additionalComments,
        country: contactForm.country,
      };
      try {
        await addContactForm({
          variables: { ...json },
        });
      } catch {
        setErrorMessage(true);
        setSubmitLoading(false);
      }
    };

    const validateForm = () => {
      return (
        !contactForm.name ||
        !contactForm.lastName ||
        !contactForm.companyName ||
        !contactForm.country ||
        !contactForm.additionalComments ||
        !recaptchaValue ||
        !validateEmail(contactForm.email)
      );
    };

    return (
      <Box className={classes.sendEmailWrapper}>
        <Box className={classes.sendEmailContentWrapper}>
          <Box className={classes.sendEmailContent}>
            <form noValidate autoComplete='off'>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth
                    value={contactForm.name || ''}
                    onChange={(event) => handleSetField('name', event.target.value)}
                    id='form_name'
                    label='Nombre'
                    variant='standard'
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth
                    value={contactForm.lastName || ''}
                    onChange={(event) => handleSetField('lastName', event.target.value)}
                    id='form_name'
                    label='Apellidos'
                    variant='standard'
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth
                    value={contactForm.companyName || ''}
                    onChange={(event) => handleSetField('companyName', event.target.value)}
                    id='form_name'
                    label='Compañía'
                    variant='standard'
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <TextField
                    fullWidth
                    value={contactForm.email || ''}
                    onChange={(event) => handleSetField('email', event.target.value)}
                    id='form_name'
                    label='Correo'
                    variant='standard'
                    onKeyUp={handleValidateEmail}
                    error={!validEmail && contactForm.email}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <FormControl variant='standard' sx={{ width: '100%' }} size='medium'>
                    <InputLabel id='demo-simple-select-standard-label'>País</InputLabel>
                    <Select
                      labelId='id-country-label'
                      id='id-country-label'
                      value={contactForm.country || ''}
                      onChange={(event) => handleSetField('country', event.target.value)}
                      style={{ marginTop: '0px' }}
                      fullWidth
                      MenuProps={{ classes: { paper: classes.menuPaper } }}
                    >
                      {countryList.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    id='standard-multiline-static'
                    label='Mensaje'
                    multiline
                    minRows={3}
                    variant='standard'
                    fullWidth
                    name='message'
                    value={contactForm.additionalComments || ''}
                    onChange={(event) => handleSetField('additionalComments', event.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid
                sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' } }}
                container
                spacing={4}
                className={classes.submitActionsBox}
              >
                <Grid item xs={12} sm={12} md={12} className={classes.sendEmailBtn}>
                  <Box className={classes.positionEnd}>
                    <ReCAPTCHA
                      style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
                      sitekey={process.env.REACT_APP_CAPTCHA_PRIVATE_KEY}
                      onChange={(value) => setRecaptchaValue(value)}
                    />
                    {submitLoading && <CircularProgress style={{ color: '#FFA81A' }} />}
                    {!submitLoading && (
                      <CustomPrimaryButton
                        label='Enviar'
                        url={''}
                        onClick={onSubmitForms}
                        disabled={validateForm()}
                        icon={
                          <ArrowForwardIosIcon
                            style={{ color: validateForm() ? 'gray' : 'white' }}
                          />
                        }
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Box className={classes.positionEnd}>
                    <p className={clsx(fontClasses.p, classes.sendEmailText)}>
                      Respetamos su privacidad. Sus datos se utilizarán únicamente para ponernos en
                      contacto con usted.
                    </p>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                sx={{ display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none' } }}
                container
                spacing={4}
                className={classes.submitActionsBox}
              >
                <Grid item xs={12} sm={12} md={12} className={classes.sendEmailBtn}>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_CAPTCHA_PRIVATE_KEY}
                    onChange={(value) => setRecaptchaValue(value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} className={classes.sendEmailBtn}>
                  <Box className={classes.positionEnd}>
                    {submitLoading && <CircularProgress style={{ color: '#FFA81A' }} />}
                    {!submitLoading && (
                      <CustomPrimaryButton
                        label='Submit'
                        url={''}
                        onClick={onSubmitForms}
                        disabled={validateForm()}
                        icon={<ArrowForwardIosIcon className={classes.buttonIcon} />}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Box className={classes.positionEnd}>
                    <p className={clsx(fontClasses.p, classes.sendEmailText)}>
                      Respetamos su privacidad. Sus datos se utilizarán únicamente para ponernos en
                      contacto con usted.
                    </p>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
        <Snackbar
          open={successMessage}
          onClose={handleCloseSuccessMsg}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant='filled' severity='success' sx={{ width: '100%' }}>
            Gracias por su interés. Nos pondremos en contacto con usted pronto
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorMessage}
          onClose={handleCloseErrorMsg}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant='filled' severity='error' sx={{ width: '100%' }}>
            Lo siento, ¡algo sucedió! Inténtalo de nuevo
          </Alert>
        </Snackbar>
      </Box>
    );
  };

  return (
    <Box>
      <Metatags
        title='Sealabs Technologies | Contacto'
        description='Estaremos encantados de ayudarle y le agradeceremos que nos elija para cualquier necesidad empresarial a la que quiera dirigirse.'
      />
      <ServicesArea />
      <SendEmail />
    </Box>
  );
};

export default Contact;
