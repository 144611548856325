import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import fontsStyles from '../../styles/fontStyles';
import useStyles from './styles';

const NavigationComponent = ({ labelPath1, title, bgColor }) => {
  const fontClasses = fontsStyles();
  const classes = useStyles();

  return (
    <Box className={classes.navigationWrapper} style={{ backgroundColor: bgColor }}>
      <Box className={classes.navigationContentWrapper}>
        <Box>
          <Box className={classes.navigationContent}>
            <Link to='/' className={classes.pathLink}>
              <HomeRoundedIcon className={clsx(fontClasses.p, classes.iconRoute)} />
            </Link>
            <Link to='/' className={classes.pathLink}>
              <p className={clsx(fontClasses.p, classes.routeTextLink)}>Home</p>
            </Link>
            <ArrowForwardIosOutlinedIcon className={clsx(fontClasses.p, classes.iconRoute2)} />
            <p className={clsx(fontClasses.p, classes.routeText)}>{labelPath1}</p>
          </Box>
          <h2 className={clsx(fontClasses.h2, classes.routeTile)}>{title}</h2>
        </Box>
      </Box>
    </Box>
  );
};

export default NavigationComponent;
