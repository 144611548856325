import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { DIMENSIONS } from '../../constants/';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  footerWrapper: {
    backgroundColor: '#EEF7FE',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  footerContent: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginTop: '100px',
    marginBottom: '50px',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginTop: '100px',
      marginBottom: '50px',
    },
  },
  footerLinks: {
    marginBottom: '5px',
    fontSize: 16,
    fontWeight: 400,
    color: '#151B25',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
    },
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: '#0077FF',
    },
  },
  footerItemBox: {
    marginTop: '30px',
    display: 'block',
    [themeBreakpoints.down('md')]: {
      marginTop: '20px',
    },
    [themeBreakpoints.down('sm')]: {
      marginTop: '20px',
    },
  },
  newsLetterBox: {
    marginTop: '30px',
    display: 'block',
    width: '85%',
    [themeBreakpoints.down('md')]: {
      width: '100%',
      marginTop: '20px',
    },
    [themeBreakpoints.down('sm')]: {
      width: '100%',
      marginTop: '20px',
    },
  },
  divisorLine: {
    backgroundColor: '#444655',
    border: 'solid 0,5px #444655',
    marginTop: '100px',
    marginBottom: '50px',
    [themeBreakpoints.down('sm')]: {
      marginTop: '50px',
      marginBottom: '50px',
    },
  },
  footerLogoBox: {
    maxWidth: '200px',
    [themeBreakpoints.down('sm')]: {
      maxWidth: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '50px',
    },
  },
  footerLogo: {
    maxWidth: '200px',
  },
  footerRights: {
    width: '100%',
    textAlign: 'right',
    fontSize: 13,
    fontWeight: 400,
    color: '#151B25',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      textAlign: 'center',
    },
  },
  footerSocialBox: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    gap: '20px',
  },
  socialIcon: {
    color: '#151B25',
    '&:hover': {
      textDecoration: 'none',
      color: '#0077FF',
    },
  },
  newsletterMessage: {
    marginTop: '20px',
    width: '100%',
    textAlign: 'justify',
    fontSize: 13,
    fontWeight: 300,
    color: '#151B25',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  buttonIcon: {
    fontSize: '20px!important',
    color: '#0077FF',
    [themeBreakpoints.down('sm')]: {
      fontSize: '16px!important',
    },
  },
}));

export default useStyles;
