import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  widgetMainButton: {
    position: 'fixed',
    bottom: '30px',
    right: '30px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignContent: 'center',
    width: '60px',
    height: '60px',
    backgroundColor: '#25d366',
    color: '#FFF',
    borderRadius: '50px',
    border: 'none',
    textAlign: 'center',
    fontSize: '30px',
    zIndex: '1000',
    cursor: 'pointer',
  },
  cardBox: {
    position: 'fixed',
    zIndex: '1000',
    width: '350px',
    background: 'white',
    transition: 'box-shadow 0.2s',
    borderRadius: '10px',
    bottom: '100px',
    right: '30px',
    padding: '20px',
    boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.2)',
    [themeBreakpoints.down('sm')]: {
      boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.2)',
      width: 'calc(100vw - 100px)',
    },
  },
  cardContentBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  widgetTitle: {
    textAlign: 'center',
    fontSize: '18px',
    marginBottom: '10px',
    fontWeight: '600',
  },
  widgetMessage: {
    width: '100%',
    marginBottom: '20px',
    textAlign: 'center',
    [themeBreakpoints.down('md')]: {
      width: '100%',
    },
  },
  widgetImage: {
    width: '150px',
    height: '100%',
    marginTop: '30px',
    marginBottom: '30px',
  },
  chatButton: {
    backgroundColor: '#0077FF',
    padding: '10px 25px',
    borderRadius: 50,
    transition: 'all .2s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  chatButtonText: {
    fontSize: '16px',
    margin: '0',
    fontWeight: 400,
    textDecoration: 'none',
    color: 'white',
    fontFamily: ['Poppins'].join(','),
  },
  widgetCloseButton: {
    position: 'absolute',
    right: '20px',
    top: '20px',
    fontSize: '20px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
  },
}));

export default useStyles;
