import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { DIMENSIONS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  whoWeAreWrapper: {
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  whoWeAreContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '150px',
    marginBottom: '75px',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginTop: '50px',
      marginBottom: '50px',
    },
  },
  teamCardsWrapper: {
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  teamCardsContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    paddingTop: '75px',
    paddingBottom: '150px',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      paddingTop: '50px',
      paddingBottom: '50px',
    },
  },
  headerSubtitle: {
    marginTop: '15px',
    textAlign: 'justify',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  teamContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '80px',
    [themeBreakpoints.down('sm')]: {
      marginTop: '50px',
    },
  },
  insideContent: {
    width: DIMENSIONS.SUB_WRAPPER,
    [themeBreakpoints.down('sm')]: {
      width: '90%',
    },
  },
  whoWeAreBoxContent: {
    width: '100%',
    marginTop: '100px',
    [themeBreakpoints.down('sm')]: {
      marginTop: '50px',
    },
  },
  valueTitle: {
    fontSize: '25px',
    fontWeight: '500',
  },
  ourValuesWrapper: {
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  ourValuesContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    display: 'flex',
    alignItems: 'center',
    paddingTop: '75px',
    paddingBottom: '75px',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      paddingTop: '50px',
      paddingBottom: '50px',
    },
  },
  ourValuesContent: {
    backgroundColor: 'white',
    marginTop: '100px',
    [themeBreakpoints.down('sm')]: {
      marginTop: '50px',
    },
  },
  buttonIcon: {
    fontSize: '28px!important',
    color: '#151B25',
    [themeBreakpoints.down('sm')]: {
      fontSize: '18px!important',
    },
  },
}));

export default styles;
